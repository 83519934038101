import './App.css';
import {Container, Row, Col} from "react-bootstrap";
import Button from "react-bootstrap/Button";

function App() {
  return (
      <Container className="App-header">
    {/*<div className="App-header">*/}
          <Row>
              <Col>
            <h2 style={{marginBottom: "2rem"}}>Welcome!</h2>
              </Col>
          </Row>
          <Row><Col>
              <Row>
        <img src={"logo.png"} alt="logo"
             style={{
               // display: "inline-block",
               maxWidth: "35%",
               minWidth: "5%",
                 maxHeight: "20%",
               minHeight: "5%",
               marginRight: "0rem",
               marginBottom: "2rem",
               padding: "0rem",
                 justifyContent: "center",
                 alignItems: "center",
             }}
        />
              </Row>
          </Col>
          </Row>
          <Row>
              <Col>
              <Row><a href={`https://docs.volorca.com`}
                      target="_blank" rel="noopener noreferrer"
                      className=''>
                  <Button className="button-main one"
                  >Learn More</Button>
              </a></Row>
              <Row>
                  <a href={`https://docs.volorca.com/quickstart/pre_alpha_setup/`}
                      target="_blank" rel="noopener noreferrer"
                      className=''>
                  <Button className="button-main two"
                  >Participate</Button>
                </a>
              </Row>
                  {/*<Row>*/}
                  {/*    <a href={`https://discord.gg/QuRuFRYErc`}*/}
                  {/*       target="_blank" rel="noopener noreferrer"*/}
                  {/*       className=''>*/}
                  {/*        <Button className="button-main two-a"*/}
                  {/*        >Join Discord</Button>*/}
                  {/*    </a>*/}
                  {/*</Row>*/}
              <Row><a href={`https://pre-alpha.volorca.com`}
                      target="_blank" rel="noopener noreferrer"
                      className=''>
                  <Button className="button-main three"
                  >Enter App</Button>
              </a></Row>
              </Col>
          </Row>

          <footer className="footer-styles">
              <a href={`https://reactjs.org`}
                 target="_blank" rel="noopener noreferrer"
                 style={{
                     textDecoration: "none",
                     color: "#faebd7",
                 }}>
                  Copyright &copy; 2023 Lubin Tan
              </a>
          </footer>


      </Container>

  );
}

export default App;
